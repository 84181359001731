@import 'https://fonts.googleapis.com/css?family=Prompt';

body {
  color: #4a4a4a;
  font-family: 'Prompt', 'Helvetica Neue', 'Open Sans', Arial, sans-serif !important;
  margin: 0;
}

.shophil-bg {
  background: linear-gradient(30deg, #2276ad, #6ec654) !important;
}

.hidden {
  display: none;
}

/**************************************
 Admnin

/* Topbar menu */
.topbar-main {
  width: '100%';
  .header {
    background-color: transparent;
    color: white !important;
    .ant-menu-item-selected,
    .ant-menu-submenu-open,
    .ant-menu-item-active {
      border-color: transparent !important;
    }
    .ant-menu-submenu-title span {
      margin-left: 5px;
    }
  }
}
.lang-dd-item {
  span {
    margin-left: 5px;
  }
}
.shophil-bg {
  width: 100%;
}
.ant-layout-sider ~ .ant-layout {
  .topbar-main {
    width: calc(100% - 200px);
  }
}

.ant-layout-sider-collapsed ~ .ant-layout {
  .topbar-main {
    width: calc(100% - 80px);
  }
}

/* Topbar second */
.topbar-second {
  &.ant-layout-header {
    background-color: white;
    position: fixed;
    top: 64px;
    width: 100%;
    z-index: 1;
  }
}

.ant-layout-sider ~ .ant-layout {
  .topbar-second {
    width: calc(100% - 200px);
  }
}

.ant-layout-sider-collapsed ~ .ant-layout {
  .topbar-second {
    width: calc(100% - 80px);
  }
}

/* select-exists-address */
.select-exists-address {
  .ant-modal-body {
    .ant-row {
      border: 1px solid #ddd;
      cursor: pointer;

      .overlay {
        position: absolute;
        right: 16px;
        display: none;
      }

      &:hover {
        background-color: #ddd;

        .overlay {
          display: block;
        }
      }

      &.active {
        background-color: #337ab7;
        color: #fff;
        .ant-typography {
          color: #fff;
        }
      }

      &.confirm {
        background-color: #e9edef;
      }
    }
  }
}

/**************************************/

.text-sub {
  color: #707070;
}

.ant-form-item {
  margin-bottom: 12px !important;
}

.ant-typography {
  &.text-sub {
    color: #707070;
  }
}

.popover-listmenu {
  .ant-popover-inner-content {
    padding: 0;
  }
}

.ant-modal {
  .ant-modal-body {
    padding: 15px;
  }
}

.ant-input-number-handler-wrap {
  visibility: hidden;
}

// font color
.font-green {
  color: #32c5d2 !important;
}

.font-red {
  color: #e7505a !important;
}

.font-grey {
  color: #c3cedd;
}

.ant-modal-mask {
  z-index: 2000 !important;
}

.ant-modal-wrap {
  z-index: 2000 !important;
}

.ant-select-dropdown {
  z-index: 2050 !important;
}

.ant-picker-dropdown {
  z-index: 2050 !important;
}

// end font color

.ant-carousel {
  .product-carousel {
    .slick-prev,
    .slick-next {
      color: #007aff !important;
      font-size: 3em;
    }
    .slick-dots {
      li button {
        height: 15px;
        border: 2px solid #fff;
        background-color: black;
      }
      li.slick-active button {
        background-color: #007aff !important;
      }
    }
  }
}

$success-color: #28a745;
$warning-color: #eca52b;
$info-color: #17a2b8;
.primary-button {
  color: #fff !important;
  background-color: $success-color !important;
  border-color: $success-color !important;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  &:hover {
    background-color: lighten($success-color, 10%) !important;
    border-color: #d9d9d9 !important;
    text-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
}

.warning-button {
  color: #fff !important;
  background-color: $warning-color !important;
  border-color: $warning-color !important;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  &:hover {
    background-color: lighten($warning-color, 10%) !important;
    border-color: #d9d9d9 !important;
    text-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
}

// COPY from /node_modules/ants/lib/style/themes/default.less
// Media queries breakpoints
// @screen-xs and @screen-xs-min is not used in Grid
// smallest break point is @screen-md
$screen-xs: 480px;
$screen-xs-min: $screen-xs;
// 👆 Extra small screen / phone

// 👇 Small screen / tablet
$screen-sm: 576px;
$screen-sm-min: $screen-sm;

// Medium screen / desktop
$screen-md: 768px;
$screen-md-min: $screen-md;

// Large screen / wide desktop
$screen-lg: 992px;
$screen-lg-min: $screen-lg;

// Extra large screen / full hd
$screen-xl: 1200px;
$screen-xl-min: $screen-xl;

// Extra extra large screen / large desktop
$screen-xxl: 1600px;
$screen-xxl-min: $screen-xxl;

@media (min-width: $screen-sm-min) {
  .visible-sm {
    display: block !important;
  }
  .row.visible-sm {
    display: flex !important;
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: -webkit-box !important;
    display: flex !important;
  }
  table.visible-sm {
    display: table !important;
  }
  tr.visible-sm {
    display: table-row !important;
  }
  th.visible-sm,
  td.visible-sm {
    display: table-cell !important;
  }
  .flex-column-sm {
    flex-direction: column;
  }
}
@media (min-width: $screen-md-min) {
  .visible-md {
    display: block !important;
  }
  .row.visible-md {
    display: flex !important;
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: -webkit-box !important;
    display: flex !important;
  }
  table.visible-md {
    display: table !important;
  }
  tr.visible-md {
    display: table-row !important;
  }
  th.visible-md,
  td.visible-md {
    display: table-cell !important;
  }
  .flex-column-md {
    flex-direction: column;
  }
}
@media (min-width: $screen-lg-min) {
  .visible-lg {
    display: block !important;
  }
  .row.visible-lg {
    display: flex !important;
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: -webkit-box !important;
    display: flex !important;
  }
  table.visible-lg {
    display: table !important;
  }
  tr.visible-lg {
    display: table-row !important;
  }
  th.visible-lg,
  td.visible-lg {
    display: table-cell !important;
  }
  .flex-column-lg {
    flex-direction: column;
  }
}
@media (min-width: $screen-xl-min) {
  .visible-xl {
    display: block !important;
  }
  .row.visible-xl {
    display: flex !important;
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: -webkit-box !important;
    display: flex !important;
  }
  table.visible-xl {
    display: table !important;
  }
  tr.visible-xl {
    display: table-row !important;
  }
  th.visible-xl,
  td.visible-xl {
    display: table-cell !important;
  }
  .flex-column-xl {
    flex-direction: column;
  }
}

@media (min-width: $screen-md-min) {
  .hidden-md {
    display: none !important;
  }
}
@media (min-width: $screen-lg-min) {
  .hidden-lg {
    display: none !important;
  }
}
@media (min-width: $screen-xl-min) {
  .hidden-xl {
    display: none !important;
  }
}
